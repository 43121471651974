import React from "react"
import { Link } from "gatsby"

import Layout from "components/layout"
import SEO from "components/seo"

const Testimonials = () => (
  <Layout pageInfo={{ pageName: "page-2" }}>
    <SEO title="Page two" />
    <h1> Testimonials</h1>
    <p>Welcome to page 2</p>
    <Link to="/">Go back to the homepage</Link>
  </Layout>
)

export default Testimonials
